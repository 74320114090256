import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, MediumIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://pancakeswap.medium.com/"
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram"
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake"
            },
            {
                label: "\u2014"
            },
            {
                label: "Online Store",
                href: "https://pancakeswap.creator-spring.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap"
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty"
            },
            {
                label: "Audits",
                href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited"
            },
            {
                label: "Careers",
                href: "https://docs.pancakeswap.finance/hiring/become-a-chef"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/pancakeswap"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        items: [
            {
                label: "English",
                href: "https://t.me/pancakeswap"
            },
            {
                label: "Bahasa Indonesia",
                href: "https://t.me/PancakeSwapIndonesia"
            },
            {
                label: "\u4E2D\u6587",
                href: "https://t.me/PancakeSwap_CN"
            },
            {
                label: "Ti\u1EBFng Vi\u1EC7t",
                href: "https://t.me/PancakeSwapVN"
            },
            {
                label: "Italiano",
                href: "https://t.me/pancakeswap_ita"
            },
            {
                label: "\u0440\u0443\u0441\u0441\u043A\u0438\u0439",
                href: "https://t.me/pancakeswap_ru"
            },
            {
                label: "T\xfcrkiye",
                href: "https://t.me/pancakeswapturkiye"
            },
            {
                label: "Portugu\xeas",
                href: "https://t.me/PancakeSwapPortuguese"
            },
            {
                label: "Espa\xf1ol",
                href: "https://t.me/PancakeswapEs"
            },
            {
                label: "\u65E5\u672C\u8A9E",
                href: "https://t.me/pancakeswapjp"
            },
            {
                label: "Fran\xe7ais",
                href: "https://t.me/pancakeswapfr"
            },
            {
                label: "Deutsch",
                href: "https://t.me/PancakeSwap_DE"
            },
            {
                label: "Filipino",
                href: "https://t.me/Pancakeswap_Ph"
            },
            {
                label: "\u10E5\u10D0\u10E0\u10D7\u10E3\u10DA\u10D8 \u10D4\u10DC\u10D0",
                href: "https://t.me/PancakeSwapGeorgia"
            },
            {
                label: "Announcements",
                href: "https://t.me/PancakeSwapAnn"
            }, 
        ]
    },
    {
        label: "Reddit",
        icon: RedditIcon,
        href: "https://reddit.com/r/pancakeswap"
    },
    {
        label: "Instagram",
        icon: InstagramIcon,
        href: "https://instagram.com/pancakeswap_official"
    },
    {
        label: "Github",
        icon: GithubIcon,
        href: "https://github.com/pancakeswap/"
    },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/pancakeswap"
    },
    {
        label: "Medium",
        icon: MediumIcon,
        href: "https://pancakeswap.medium.com/"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
