import { ChainId } from '@savvydex/sdk'

export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.NEXT_PUBLIC_GRAPH_API_PREDICTION
export const GRAPH_API_LOTTERY = process.env.NEXT_PUBLIC_GRAPH_API_LOTTERY
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
export const API_NFT = process.env.NEXT_PUBLIC_API_NFT
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'
export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const GRAPH_API_NFTMARKET = process.env.NEXT_PUBLIC_GRAPH_API_NFT_MARKET
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const GALAXY_NFT_CLAIMING_API = 'https://graphigo.prd.galaxy.eco/query'

export const ApiEndpoints: Record<ChainId, string> = {
  [ChainId.MAINNET]: 'https://dex-api.savvycom.ai/api', // bsc mainnet
  [ChainId.TESTNET]: 'https://lab-dex-api.savvycom.xyz/api', // bsc testnet
  // [ChainId.ETHEREUM]: '',
  // [ChainId.GOERLI]: '',
  // [ChainId.RINKEBY]: '',
  [ChainId.POLYGON]: 'https://dex-api.savvycom.ai/api', // polygon mainnet
  [ChainId.MUMBAI]: 'https://lab-dex-api.savvycom.xyz/api', // polygon testnet
}

export const ChartSubGrapEndPoints: Record<ChainId, string> = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/bsc-exchange', // bsc mainnet
  [ChainId.TESTNET]: 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2', // bsc testnet
  // [ChainId.ETHEREUM]: '',
  // [ChainId.GOERLI]: '',
  // [ChainId.RINKEBY]: '',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/polygon-exchange', // polygon mainnet
  [ChainId.MUMBAI]: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/mumbai-exchange', // polygon testnet
}

export const BlockClients: Record<ChainId, string> = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks', // bsc mainnet
  [ChainId.TESTNET]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks', // bsc testnet
  // [ChainId.ETHEREUM]: '',
  // [ChainId.GOERLI]: '',
  // [ChainId.RINKEBY]: '',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/thanhnv2597/savvy-dex-block-polygon', // polygon mainnet
  [ChainId.MUMBAI]: 'https://api.thegraph.com/subgraphs/name/andreinnguyen/mumbai-block', // polygon testnet
}
