import _define_property from "@swc/helpers/src/_define_property.mjs";
/* eslint-disable import/no-cycle */ import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["MAINNET"] = 56] = "MAINNET";
    ChainId[ChainId["TESTNET"] = 97] = "TESTNET";
    ChainId[ChainId["POLYGON"] = 137] = "POLYGON";
    ChainId[ChainId["MUMBAI"] = 80001] = "MUMBAI";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
var _obj;
// const FACTORY_ADDRESS_ETH = '0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB'
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.MAINNET, FACTORY_ADDRESS), _define_property(_obj, ChainId.TESTNET, "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc"), _define_property(_obj, ChainId.POLYGON, "0x70b5622B968a23FF77241376F0B76f63BE8EB76c"), _define_property(_obj, ChainId.MUMBAI, "0x06e6Cdc98378b7c9C9E0C29da2452176896428AB"), _obj);
export var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var _obj1;
// const INIT_CODE_HASH_ETH = '0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d'
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.MAINNET, INIT_CODE_HASH), _define_property(_obj1, ChainId.TESTNET, "0xecba335299a6693cb2ebc4782e74669b84290b6378ea3a3873c7231a8d7d1074"), _define_property(_obj1, ChainId.POLYGON, "0xa5934690703a592a07e841ca29d5e5c79b5e22ed4749057bb216dc31100be1c0"), _define_property(_obj1, ChainId.MUMBAI, "0xa5934690703a592a07e841ca29d5e5c79b5e22ed4749057bb216dc31100be1c0"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.MUMBAI, new Token(ChainId.MUMBAI, "0x923e28411F67cC66918E684de4C65742600625Da", 18, "WMATIC", "Wrapped Matic", "https://weth.io")), _define_property(_obj3, ChainId.POLYGON, new Token(ChainId.POLYGON, "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", 18, "WMATIC", "Wrapped Matic", "https://weth.io")), _obj3);
var _obj4;
export var WBNB = (_obj4 = {}, _define_property(_obj4, ChainId.MAINNET, new Token(ChainId.MAINNET, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.TESTNET, new Token(ChainId.TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _obj4);
var _obj5;
export var WNATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.MUMBAI, WETH9[ChainId.MUMBAI]), _define_property(_obj5, ChainId.POLYGON, WETH9[ChainId.POLYGON]), _define_property(_obj5, ChainId.MAINNET, WBNB[ChainId.MAINNET]), _define_property(_obj5, ChainId.TESTNET, WBNB[ChainId.TESTNET]), _obj5);
var _obj6;
export var NATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.MUMBAI, {
    name: "Mumbai Matic",
    symbol: "MATIC",
    decimals: 18
}), _define_property(_obj6, ChainId.POLYGON, {
    name: "Polygon Matic",
    symbol: "MATIC",
    decimals: 18
}), _define_property(_obj6, ChainId.MAINNET, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj6, ChainId.TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _obj6);
