import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-size: 16px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  border-color: ",
        ";\n  border-top-width: 1px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  padding: 24px 0;\n  margin-bottom: 24px;\n\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        '\n  width: 100%;\n  background-color: #0f282a;\n  padding-top: 24px;\n\n  .logo {\n    display: flex;\n    justify-content: center;\n  }\n\n  .description {\n    padding: 20px 30px 30px 30px;\n    border-bottom: 1px solid #ffffffa5;\n\n    p {\n      margin: auto;\n      max-width: 970px;\n      font-family: "Open Sans", sans-serif;\n      font-style: normal;\n      font-weight: 400;\n      font-size: 16px;\n      line-height: 24px;\n      text-align: center;\n      color: #ffffffa5;\n    }\n\n    .p2 {\n      font-size: 12px;\n    }\n  }\n\n  .social {\n    display: flex;\n    padding: 51px 0;\n    justify-content: center;\n\n    .social-content {\n      min-width: 80%;\n      display: flex;\n      justify-content: center;\n      gap: 42px;\n      flex-wrap: wrap;\n      padding-bottom: 30px;\n    }\n\n    .social-item {\n      font-family: "Google Sans", sans-serif;\n      font-weight: 700;\n      display: flex;\n      gap: 15px;\n      font-size: 18px;\n      color: #ffffffa5;\n      cursor: pointer;\n      align-items: center;\n    }\n  }\n'
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-57b2437b-0"
})(_templateObject(), darkColors.backgroundAlt);
export var StyledList = styled.ul.withConfig({
    componentId: "sc-57b2437b-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-57b2437b-2"
})(_templateObject2(), darkColors.secondary);
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-57b2437b-3"
})(_templateObject3());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-57b2437b-4"
})(_templateObject4(), darkColors.cardBorder, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-57b2437b-5"
})(_templateObject5(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-57b2437b-6"
})(_templateObject6(), darkColors.text);
export var FooterRoot = styled.div.withConfig({
    componentId: "sc-57b2437b-7"
})(_templateObject7());
