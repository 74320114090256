import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { background, border, layout, position, space } from "styled-system";
var Box = styled.div.withConfig({
    componentId: "sc-c4ec0fdf-0"
})(_templateObject(), background, border, layout, position, space);
export default Box;
