export default {
  masterChef: {
    97: '0x6E2Aa59E3e6B02D4557Dd4Ac4F65B5dc922d1163',
    56: '0x4D5Dd8B0Ab74E83B4c8e60Efd46ABD66782e910f',
    80001: '0x41035290509adf688fea9649ab6371f21155578a',
    137: '0x402dB9E97d176E0F62109a6162Ebc0BD0ba56E52', // TODO: just update address later
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: {
    // 1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    // 4: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    // 5: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    80001: '0xB213c916903bBF90f1cF990Ab017a65F65bBB6eD',
    137: '0x85765f87230208863DD2F51F332940f4F7a551e6',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0xC5C916ECAAc097E4935a2636cD7210Dbc887474e', // cakeVault from testnet created on https://testnet.bscscan.com/address/0x2ad35cd5a849091ff5a782b9613320bbb031bae9
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  galaxyNftClaiming: {
    56: '0x2D18f2d27D50C9b4013DEBA3D54f60996bD8847E',
    97: '',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  bridgeSvc: {
    56: '0xF8f0Db4c649AF275d8f919C3814d4A4CAE680939',
    97: '0x334691f03b667C83Be0e6Fc4EF66673b2BCA2ED6',
    80001: '0x334691f03b667C83Be0e6Fc4EF66673b2BCA2ED6',
    137: '0xF8f0Db4c649AF275d8f919C3814d4A4CAE680939',
  },
  svc: {
    56: '0x857b64d4A0ce517631210eaf9d512BC04485b439',
    97: '0xFF3b8ae2FDfbA5daAdcee60FC3E346CAdBcdfb3c',
    80001: '0xFF3b8ae2FDfbA5daAdcee60FC3E346CAdBcdfb3c',
  },
  svcPolygon: {
    56: '0x37856fc36f6981733b456F3C785407e28A933d3C',
    97: '0x871399caa2777C452E54700fd226fEfC1D1854c1',
    80001: '0x871399caa2777C452E54700fd226fEfC1D1854c1',
    137: '0x37856fc36f6981733b456F3C785407e28A933d3C',
  },
}
